import React, { createContext, useContext } from "react";

const AppScreenThemeContext = createContext<
  "android" | "cupertino" | undefined
>(undefined);

export const useAppScreenTheme = () => useContext(AppScreenThemeContext);

export const getAppTheme = () =>
  typeof window === "undefined"
    ? "android"
    : /iphone|ipad|ipod/i.test(navigator?.userAgent.toLowerCase() ?? "")
      ? "cupertino"
      : "android";

interface AppScreenThemeProviderProps {
  children: React.ReactNode;
}
export const AppScreenThemeProvider: React.FC<AppScreenThemeProviderProps> = ({
  children,
}) => {
  return (
    <AppScreenThemeContext.Provider value={getAppTheme()}>
      {children}
    </AppScreenThemeContext.Provider>
  );
};
