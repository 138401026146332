import pRetry from "p-retry";
import pTimeout from "p-timeout";
import * as z from "zod";

import {
  DEFAULT_APP,
  DEFAULT_REGION,
  DEFAULT_USER,
  LOCAL_STORAGE_FALLBACK_APP_KEY,
  LOCAL_STORAGE_FALLBACK_REGION_KEY,
  LOCAL_STORAGE_FALLBACK_USER_KEY,
} from "../constants";
import { bridge } from "./bridge";

const BRIDGE_TIMEOUT_MS = 100;

export const SchemaApp = z.object({
  userAgent: z.string(),
  deviceIdentity: z.string().optional(),
  locale: z.string().optional(),
  appHost: z.string().optional(),
  country: z.enum(["KR", "GB", "CA", "JP"]).optional(),
  appVersionCode: z.string().optional(),
});

export const SchemaRegion = z.object({
  id: z.number(),
  name: z.string(),
});

export const SchemaUser = z.object({
  id: z.number(),
  authToken: z.string(),
  phone: z.string(),
  nickname: z.string(),
  profileImage: z.string(),
});

export async function getApp() {
  if (DEFAULT_APP) {
    return SchemaApp.parse(DEFAULT_APP);
  }

  try {
    if (typeof window === "undefined") {
      return SchemaApp.parse(DEFAULT_APP);
    }

    const appResp = await pRetry(
      () =>
        pTimeout(bridge.getAppInfo({}), {
          milliseconds: BRIDGE_TIMEOUT_MS,
        }),
      {
        retries: 3,
        minTimeout: 16,
      }
    );
    const app = SchemaApp.parse(appResp.info.app);
    localStorage.setItem(LOCAL_STORAGE_FALLBACK_APP_KEY, JSON.stringify(app));

    return app;
  } catch (error) {
    const serialized = localStorage.getItem(LOCAL_STORAGE_FALLBACK_APP_KEY);

    if (!serialized) {
      throw new Error("app not found");
    }

    return SchemaApp.parse(JSON.parse(serialized));
  }
}

export async function getRegion() {
  if (DEFAULT_REGION) {
    return SchemaRegion.parse(DEFAULT_REGION);
  }

  try {
    if (typeof window === "undefined") {
      return SchemaRegion.parse(DEFAULT_REGION);
    }

    const regionResp = await pRetry(
      () =>
        pTimeout(bridge.getRegionInfo({}), {
          milliseconds: BRIDGE_TIMEOUT_MS,
        }),
      {
        retries: 3,
        minTimeout: 100,
      }
    );
    const region = SchemaRegion.parse(regionResp.info.region);
    localStorage.setItem(
      LOCAL_STORAGE_FALLBACK_REGION_KEY,
      JSON.stringify(region)
    );

    return region;
  } catch {
    const serialized = localStorage.getItem(LOCAL_STORAGE_FALLBACK_REGION_KEY);

    if (!serialized) {
      throw new Error("region not found");
    }

    return SchemaRegion.parse(JSON.parse(serialized));
  }
}

export async function getUser() {
  if (DEFAULT_USER) {
    return SchemaUser.parse(DEFAULT_USER);
  }

  try {
    if (typeof window === "undefined") {
      return SchemaUser.parse(DEFAULT_USER);
    }

    const userResp = await pRetry(
      () =>
        pTimeout(bridge.getUserInfo({}), {
          milliseconds: BRIDGE_TIMEOUT_MS,
        }),
      {
        retries: 3,
        minTimeout: 16,
      }
    );
    const user = SchemaUser.parse(userResp.info.user);
    localStorage.setItem(LOCAL_STORAGE_FALLBACK_USER_KEY, JSON.stringify(user));

    return user;
  } catch {
    const serialized = localStorage.getItem(LOCAL_STORAGE_FALLBACK_USER_KEY);

    if (!serialized) {
      return null;
    }

    return SchemaUser.parse(JSON.parse(serialized));
  }
}
