import "@seed-design/stylesheet/global.css";
import "@stackflow/plugin-basic-ui/index.css";
import "./src/styles/global.css";

import React from "react";
import { WrapPageElementBrowserArgs } from "gatsby";

import { AppScreenThemeProvider } from "./src/AppScreenThemeContext";
import { Stack } from "./src/stackflow";
import { BridgeProvider } from "./src/bridge/bridge";
import BridgeInfoProvider from "./src/resources/bridgeInfoResource";

export const wrapPageElement = ({
  props: { location, data },
}: WrapPageElementBrowserArgs) => {
  return (
    <>
      <BridgeProvider>
        <AppScreenThemeProvider>
          <React.Suspense>
            <BridgeInfoProvider>
              <Stack
                initialContext={{ req: { path: location.pathname }, data }}
              />
            </BridgeInfoProvider>
          </React.Suspense>
        </AppScreenThemeProvider>
      </BridgeProvider>
    </>
  );
};
