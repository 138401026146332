export function parseUrl(pathname: string) {
  if (!pathname) {
    return;
  }

  const paths = pathname.replace(/^\/|\/$/g, "").split("/");

  return paths.length > 1
    ? { env: paths[0], uid: paths[1] }
    : { uid: paths[0] };
}
