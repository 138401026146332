import React, { createContext, Suspense, useContext, useMemo } from "react";
import {
  getApp,
  getRegion,
  getUser,
  SchemaApp,
  SchemaRegion,
  SchemaUser,
} from "../bridge/bridgeInfo";
import { parseUrl } from "../utils/parseUrl";

import z from "zod";

export interface BridgeInfo {
  app: z.infer<typeof SchemaApp> | null;
  region: z.infer<typeof SchemaRegion> | null;
  user: z.infer<typeof SchemaUser> | null;
}

function makeBridgeInfoResource() {
  type State =
    | {
        _t: "pending";
      }
    | {
        _t: "rejected";
        result: Error;
      }
    | {
        _t: "resolved";
        result: BridgeInfo;
      };

  let state: State = {
    _t: "pending",
  };

  const promise = (async () => {
    try {
      const [app, region, user] = await Promise.all([
        getApp(),
        getRegion(),
        getUser(),
      ]);

      if (!region?.id) {
        return void (state = {
          _t: "rejected",
          result: new Error("bridge.info.region() not found"),
        });
      }

      state = {
        _t: "resolved",
        result: {
          app,
          user,
          region,
        },
      };
    } catch (error) {
      state = {
        _t: "rejected",
        result: error as any,
      };
    }
  })();

  return {
    read() {
      switch (state._t) {
        case "pending":
          throw promise;
        case "rejected":
          throw state.result;
        case "resolved":
          return state.result;
      }
    },
  };
}

const bridgeInfoResource = makeBridgeInfoResource();

const BridgeInfoResourceContext = createContext<BridgeInfo>(null as any);

const BridgeInfoProvider: React.FCWithChildren = (props) => {
  if (typeof window === "undefined") {
    return <BridgeInfoSSRProvider>{props.children}</BridgeInfoSSRProvider>;
  }

  return (
    <BridgeInfoResourceProvider>{props.children}</BridgeInfoResourceProvider>
  );
};

const BridgeInfoSSRProvider: React.FCWithChildren = (props) => {
  return (
    <BridgeInfoResourceContext.Provider
      value={{
        app: null,
        user: null,
        region: null,
      }}
    >
      {props.children}
    </BridgeInfoResourceContext.Provider>
  );
};

const BridgeInfoResourceProvider: React.FCWithChildren = (props) => {
  const pathname = window.location.pathname;
  const path = parseUrl(pathname);
  const bridgeInfo =
    path?.env === "wv"
      ? bridgeInfoResource.read()
      : {
          app: null,
          user: null,
          region: null,
        };

  return (
    <BridgeInfoResourceContext.Provider
      value={useMemo(
        () => ({
          app: bridgeInfo?.app,
          user: bridgeInfo?.user,
          region: bridgeInfo?.region,
        }),
        [bridgeInfo?.app, bridgeInfo?.user, bridgeInfo?.region]
      )}
    >
      {props.children}
    </BridgeInfoResourceContext.Provider>
  );
};

export function useBridgeInfo() {
  return useContext(BridgeInfoResourceContext);
}

export default BridgeInfoProvider;
