import * as z from 'zod'

import { LOCAL_STORAGE_STATE_KEY } from '../constants'

type Setter<S> = (fn: (prevState: S) => S) => void

const SchemaStorage = z.object({
 pageViewCount : z.number(),
 isRegularCustomerHalfViewShown: z.boolean(),
})

type ISchemaStorage = z.infer<typeof SchemaStorage>

const initialState: ISchemaStorage = {
  pageViewCount: 0,
  isRegularCustomerHalfViewShown: false,
}

export function initialPersistedState(): ISchemaStorage {
  if (typeof window === 'undefined') {
    return initialState
  }

  localStorage.setItem(LOCAL_STORAGE_STATE_KEY, JSON.stringify(initialState))

  return initialState
}

export const getStorage = () => {
  if (typeof window === 'undefined') {
    return initialState
  }

  const serialized = localStorage.getItem(LOCAL_STORAGE_STATE_KEY)

  if (!serialized) {
    return initialPersistedState()
  }
  try {
    const parsed = JSON.parse(serialized)

    const ignoreExtraKeyState = SchemaStorage.strip().parse(parsed)

    localStorage.setItem(
      LOCAL_STORAGE_STATE_KEY,
      JSON.stringify(ignoreExtraKeyState)
    )

    return ignoreExtraKeyState
  } catch {
    return initialPersistedState()
  }
}

export const setStorage: Setter<ISchemaStorage> = (fn) => {
  if (typeof window === 'undefined') {
    return initialState
  }

  const prevStorageData = getStorage()
  const nextStorageData = fn(prevStorageData)
  localStorage.setItem(LOCAL_STORAGE_STATE_KEY, JSON.stringify(nextStorageData))

  return nextStorageData
}
