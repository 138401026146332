module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-next-seo-virtual-170c64bfab/0/cache/gatsby-plugin-next-seo-npm-1.10.0-e59979a254-ed02f47b12.zip/node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-95c0682425/0/cache/gatsby-plugin-manifest-npm-4.24.0-3629f3f328-5040a3844e.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"theme_color":"#ffffff","background_color":"#ffffff","display":"minimal-ui","icon":"src/assets/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7dcc39f261bfd67ba48775b30250e407"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-prismic-previews-virtual-d1a08e86e8/0/cache/gatsby-plugin-prismic-previews-npm-5.2.10-db8eb2b194-8b37c9ef65.zip/node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"daangnlandingpages","accessToken":"MC5Zc1Q4QWhBQUFDZ0FCMk5K.dFDvv70I77-9Dnrvv73vv73vv73vv73vv70g77-9QO-_ve-_ve-_ve-_vSBFUO-_vXJl77-9Ae-_vTfvv73vv71a","promptForAccessToken":true,"apiEndpoint":"https://daangnlandingpages.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-0df459f7e6/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
