import React, { useEffect } from "react";
import { vars } from "@seed-design/design-token";
import { ActivityComponentType } from "@stackflow/react";
import { BottomSheet } from "@stackflow/plugin-basic-ui";
import { rem } from "polished";

import { useFlow } from "../../lib/useFlow";
import { ReactComponent as Karrot } from "./karrot.svg";
import { ReactComponent as Check } from "./check.svg";
import * as css from "./RegularCustomerNudging.css";
import { setStorage } from "../../lib/storage";
import { BUSINESS_PROFILE_API } from "../../constants";
import { bridge } from "../../bridge/bridge";
import { useBridgeInfo } from "../../resources/bridgeInfoResource";

const KARROT_BIZACCOUNT_ID = 1286318;
// ref: https://ba.alpha.kr.krmt.io/swagger-ui/index.html?urls.primaryName=Business%20Account%20V2#/business-account-controller-v-2/followV2
const KARROT_BIZACCOUNT_FOLLOW_API = `${BUSINESS_PROFILE_API}/api/v2/business_accounts/${KARROT_BIZACCOUNT_ID}/follow`;

type BottomSheetParams = {
  uid: string;
};
const BottomSheetPurposBizPostConfig: ActivityComponentType<
  BottomSheetParams
> = ({ params }) => {
  const { pop } = useFlow();
  const { user } = useBridgeInfo();

  const handleOkButtonClick = async () => {
    const result = await fetch(KARROT_BIZACCOUNT_FOLLOW_API, {
      method: "POST",
      headers: {
        "X-AUTH-TOKEN": user?.authToken ?? "",
      },
    });

    if (result.ok) {
      bridge.openToast({
        toast: {
          body: "당근마켓 공식계정과 단골을 맺었어요.",
          type: "TOAST",
        },
      });
    }

    bridge.logEvent({
      analytics: {
        target: "FIREBASE",
        name: "click_my_daangn_story_button",
        params: JSON.stringify({
          post_id: params.uid,
          id: "dangol-halfveiw",
        }),
      },
    });

    pop();
  };

  const handleCancelButtonClick = () => {
    pop();
  };

  useEffect(() => {
    setStorage((prev) => ({
      ...prev,
      isRegularCustomerHalfViewShown: true,
    }));
  }, []);

  return (
    <BottomSheet borderRadius={rem(20)}>
      <section className={css.section}>
        <div className={css.chapter}>
          <h2 className={css.title}>
            <div>당근마켓과 단골을 맺고</div>
            <div>더 많은 소식을 받아보시겠어요?</div>
          </h2>
          <div className={css.appBio}>
            <div className={css.imageWrapper}>
              <div className={css.image}>
                <Karrot />
              </div>
            </div>
            <div className={css.imageDesc}>
              <span className={css.appTitle}>당근마켓</span>
              <Check />
            </div>
          </div>
        </div>
        <div className={css.chapter}>
          <div className={css.buttonGroup}>
            <button className={css.activeButton} onClick={handleOkButtonClick}>
              좋아요
            </button>
            <button className={css.button} onClick={handleCancelButtonClick}>
              다음에 할게요
            </button>
          </div>
        </div>
      </section>
    </BottomSheet>
  );
};

export default BottomSheetPurposBizPostConfig;
